import { Platform, useWindowDimensions } from 'react-native';

export const useIsMobile = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= 650;

  const isWeb = Platform.OS === 'web';

  const isNative = Platform.OS !== 'web';

  return { isMobile, isWeb, isNative };
};
