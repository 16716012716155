import { useCustomer } from './useCustomer';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useBrand } from './useBrand';
import { useQuery } from '@apollo/client';
import { useAuth } from './useAuth';
import { useError } from './useError';
import { isDoctorPortal } from '../utils/isDoctorPortal';
import { graphql } from '../../__generated__';

export const useLiveChat = () => {
  const { customer, signedin } = useCustomer();

  const { handleError } = useError();

  const brand = useBrand();

  const { authenticated } = useAuth();

  const useZendesk = brand === 'onlinedoctor' || brand === 'truegreen';
  const useIntercom = brand === 'heyfella' || brand === 'leafdoctors';

  const { data } = useQuery(
    graphql(`
      query getTokensPush($brand: String) {
        getTokens(brand: $brand) {
          helpDeskToken
          intercomToken
        }
      }
    `),
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      variables: {
        brand,
      },
      skip: !(authenticated && customer?.id),
    },
  );

  useEffect(() => {
    if (useIntercom && data?.getTokens?.intercomToken && window['Intercom']) {
      try {
        Intercom('update', {
          name: customer?.name,
          user_id: customer?.id,
          user_hash: data?.getTokens?.intercomToken,
        });
      } catch (e) {
        handleError(e);
      }
    }
  }, [data?.getTokens?.intercomToken, customer?.name, useIntercom]);

  useEffect(() => {
    if (useZendesk && data?.getTokens?.helpDeskToken && window['zE']) {
      try {
        zE('messenger', 'loginUser', function (callback) {
          callback(data?.getTokens?.helpDeskToken);
        });
      } catch (e) {
        handleError(e);
      }
    }
  }, [data?.getTokens?.helpDeskToken, , useZendesk]);

  useEffect(() => {
    if (isDoctorPortal()) return;

    if (signedin && customer?.name && customer?.email) {
      try {
        try {
          if (Sentry) {
            Sentry.setUser({ email: customer.email });
            Sentry.setContext('brand', brand);
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (LogRocket) {
            LogRocket.identify(customer.id, {
              name: customer.name,
              email: customer.email,
              brand: brand,
            });
          }
        } catch (e) {}

        try {
          if (_cio) {
            _cio.identify({
              id: customer.id,
            });
          }
        } catch (e) {}

        try {
          dataLayer.push({
            signedin: true,
            user_id: customer.id,
            email: customer.email,
            mobile: customer.mobile,
          });
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [signedin, customer?.name, customer?.email, customer?.doctor]);
};
