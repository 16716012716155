import { useEffect } from 'react';
import { useBrandName } from '../../Common/hooks/useBrand';
import { gql, useLazyQuery } from '@apollo/client';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useError } from '../../Common/hooks/useError';
import { usePersistantFlow } from '../../Common/hooks/usePersistantFlow';
import { isBrandsPaymentLive } from '../../Common/hooks/useBrandPaymentsLive';

export const supportsApplePayState = atom({
  key: 'supportsApplePayState',
  default: false,
});

export const usePaymentsApplePaySupport = () => {
  const { handleError } = useError();

  const livePayments = isBrandsPaymentLive();

  const { paymentApplePayIDProd, paymentApplePayIDDev, brand } = useBrandName();

  const setSupportsApplePayState = useSetRecoilState(supportsApplePayState);

  const checkApplePay = async () => {
    if (window.ApplePaySession && brand === 'onlinedoctor') {
      const canMakePayments = await ApplePaySession.canMakePaymentsWithActiveCard(
        livePayments ? paymentApplePayIDProd : paymentApplePayIDDev,
      );
      setSupportsApplePayState(canMakePayments);
    }
  };

  useEffect(() => {
    checkApplePay().catch((e) => handleError(e));
  }, []);
};

export const usePaymentsApplePay = (submit) => {
  const { name } = useBrandName();

  const canUseApplePay = useRecoilValue(supportsApplePayState);
  const livePayments = isBrandsPaymentLive();

  const [fetchApplePaySession] = useLazyQuery(
    gql`
      query getApplePaySession($live: Boolean) {
        getApplePaySession(live: $live)
      }
    `,
  );

  const {
    totalAmount,
    state: { service },
  } = usePersistantFlow();

  const startApplePay = () => {
    const request = {
      countryCode: 'AU',
      currencyCode: 'AUD',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['visa', 'masterCard'],
      lineItems: [
        {
          label: service?.name,
          type: 'final',
          amount: totalAmount(),
        },
      ],
      total: {
        label: name,
        amount: totalAmount(),
      },
    };

    const session = new ApplePaySession(14, request);

    session.onvalidatemerchant = async function (event) {
      const { data } = await fetchApplePaySession({
        variables: {
          live: livePayments,
        },
      });

      session.completeMerchantValidation(JSON.parse(data?.getApplePaySession));
    };

    session.onpaymentauthorized = function (event) {
      const payload = JSON.stringify({
        type: 'applepay',
        token: JSON.stringify(event.payment.token.paymentData),
      });

      session.completePayment(ApplePaySession.STATUS_SUCCESS);

      submit(payload);
    };

    session.begin();
  };

  return {
    canUseApplePay,
    startApplePay,
  };
};
