import { useRecoilState } from 'recoil';
import { useAuth } from './useAuth';
import { useAnalytics } from './useAnalytics';
import currency from 'currency.js';
import { useEffect, useState } from 'react';
import { persistedFlowState } from './persistedFlowState';
import { useRouting } from './useRouting';
import { uuid } from '../utils/uuid';
import { useIsMobile } from './useIsMobile';
import { PricingTier, ServiceOption } from '../../__generated__/graphql';
import { PersistedFlow } from './persistedFlowType';
import { useCustomer } from './useCustomer';

export const usePersistantFlow = () => {
  const { navigate } = useRouting();

  const [promoCode, setPromoCode] = useState();

  const { authenticated } = useAuth();

  const { customerCompletedProfile } = useCustomer();

  const [state, setState] = useRecoilState(persistedFlowState);

  const clearFlow = () => setState(null);

  const { trackEvent } = useAnalytics();

  const { isNative } = useIsMobile();

  const startFlow = (
    pricing: PricingTier,
    service: ServiceOption,
    formID?: string,
    formValues?: Record<string, string>,
  ) => {
    const event: PersistedFlow = {
      pricing,
      service,
      selectedOption: null,
      id: uuid(),
      forms: formID && formValues ? { [formID]: formValues } : {},
      subscribe: false,
      startedAt: new Date(),
      startedAuthenticated: authenticated,
      step: formID && formValues ? '/start/info' : '/start',
    };

    setState(event);

    trackEvent('started_request', event);

    if (!isNative && formID && formValues) {
      navigate(`/start/info`);
    } else if (!isNative) {
      navigate(`/start`);
    }
  };

  const setForm = (formID: string, formValues) => {
    setState((prev) => {
      return { ...prev, forms: { ...prev.forms, [formID]: formValues } };
    });
  };

  const setStep = (step: string) => {
    setState((prev) => {
      return { ...prev, step };
    });
  };

  const setAppointment = (appointment) => {
    setState((prev) => {
      return { ...prev, appointment };
    });
  };

  const setSubscribe = (subscribe) => {
    setState((prev) => {
      return { ...prev, subscribe };
    });
  };

  const totalAmount = () => {
    const additions =
      state?.service?.options?.find((b) => b.id === state.selectedOption)?.price ?? 0;

    let total = currency(state.pricing.amount).add(additions);

    if (promoCode && promoCode?.discount && promoCode?.discount > 0) {
      total = total.subtract(promoCode?.discount);
    }

    return total;
  };

  const totalAmountFormatted = () => {
    return currency(totalAmount()).format();
  };

  const optionAdded = (optionID) => {
    return state?.selectedOption === optionID;
  };

  const toggleOption = (optionID) => {
    setState((prev) => {
      if (optionID === prev.selectedOption) {
        trackEvent('removed_option', optionID);

        return { ...prev, selectedOption: null };
      } else {
        trackEvent('added_option', optionID);

        return { ...prev, selectedOption: optionID };
      }
    });
  };

  return {
    startFlow,
    setStep,
    state,
    clearFlow,
    setForm,
    setAppointment,
    setSubscribe,
    totalAmountFormatted,
    totalAmount,
    toggleOption,
    optionAdded,
    promoCode,
    setPromoCode,
  };
};
