import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { customerFragment } from '../../GraphQL/Fragments/customer';
import { useAuth } from './useAuth';

export const GET_CUSTOMER = gql`
  ${customerFragment}
  query customerSessionQuery {
    getCustomer {
      ...customerFragment
    }
  }
`;

export const useCustomer = () => {
  const { authenticated } = useAuth();

  const [editingCustomer, _setEditingCustomer] = useState(null);
  const [hasChangedEditingCustomer, setHasChangedEditingCustomer] = useState(false);

  const [editCustomerMutation, { loading: saving }] = useMutation(gql`
    ${customerFragment}
    mutation editCustomerMutation(
      $given: String
      $surname: String
      $mobile: String
      $email: String
      $gender: String
      $dob: String
    ) {
      updateCustomer(
        given: $given
        surname: $surname
        mobile: $mobile
        email: $email
        dob: $dob
        gender: $gender
      ) {
        ...customerFragment
      }
    }
  `);

  const { loading, data, error, refetch } = useQuery(GET_CUSTOMER, {
    skip: !authenticated,
    fetchPolicy: 'cache-and-network',
  });

  const [
    updateDoctorStatusMutation,
    { loading: updatingDoctorStatus, error: updatingDoctorStatusError },
  ] = useMutation(gql`
    mutation doctorChangeStatusMutation($status: Boolean) {
      doctorChangeStatus(status: $status) {
        id
        __typename
        available
        statusColor
        statusIcon
        statusMessage
        statusMessageHome
        statusMessageOrder
        pricingTiers {
          id
          name
          description
          amount
        }
        availableDoctors {
          id
          doctorImage
          name
        }
      }
    }
  `);

  const updateDoctorStatus = async (status) => {
    await updateDoctorStatusMutation({
      variables: {
        status: status,
      },
    });

    await refetch();
  };

  const editCustomer = async () => {
    if (saving || !hasChangedEditingCustomer) return;

    await editCustomerMutation({
      variables: {
        given: editingCustomer.given,
        surname: editingCustomer.surname,
        mobile: editingCustomer.mobile,
        email: editingCustomer.email,
        gender: editingCustomer.gender,
        dob: editingCustomer.dob,
      },
      refetchQueries: [GET_CUSTOMER],
      awaitRefetchQueries: true,
    });

    setHasChangedEditingCustomer(false);
  };

  const completedProfile = data?.getCustomer?.completedProfile;

  const setEditingCustomer = (obj) => {
    setHasChangedEditingCustomer(true);
    _setEditingCustomer(obj);
  };

  useEffect(() => {
    if (data) {
      _setEditingCustomer(data.getCustomer);
    }
  }, [data]);

  return {
    loadingCustomer: data?.getCustomer === null || editingCustomer === null,
    customer: data?.getCustomer || {},
    isDoctor: data?.getCustomer?.doctor,
    doctor: data?.getCustomer?.doctor,
    internal: data?.getCustomer?.internal ?? false,
    signedin: authenticated && data?.getCustomer !== null,
    loading: loading,
    isDoctorApplying: data?.getCustomer?.doctorApplying,
    editingCustomer,
    editingCustomerSaving: saving,
    editCustomer,
    hasChangedEditingCustomer,
    setEditingCustomer,
    customerCompletedProfile: completedProfile,
    updateDoctorStatus,
    updatingDoctorStatus,
    refetchCustomer: refetch,
  };
};
