import { atom } from 'recoil';
import { PersistedFlow } from './persistedFlowType';

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const persistedFlowState = atom<PersistedFlow | null>({
  key: 'persistedflow',
  default: null,
  effects: [localStorageEffect('persistedflow')],
});
