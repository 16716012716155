import React from 'react';
import { Box, Text, Checkbox, Select, VStack, HStack } from 'native-base';
import { useRecoilState } from 'recoil';
import { devState } from '../state/devState';
import { useWindowDimensions } from 'react-native';

export function DevSwitcher() {
  const [state, setState] = useRecoilState(devState);

  const onSetBrand = (brand: string) => {
    setState((existingState) => {
      return { ...existingState, brand };
    });

    window.document.location = '/';
  };

  const onSetDev = (dev: boolean) => {
    setState((existingState) => {
      return { ...existingState, dev };
    });

    window.location.reload();
  };

  const { width } = useWindowDimensions();

  return (
    <>
      {!state.dev && (
        <Text color={'white'} position={'fixed'} left={0} top={0} bg={'red.500'} px={8} py={2}>
          Production
        </Text>
      )}
      <HStack
        space={2}
        position={'fixed'}
        style={{ left: width / 2 }}
        bottom={0}
        borderRadius={'sm'}
        bg={'gray.100'}
        alignItems={'center'}
        p={1}
      >
        <Select w={40} selectedValue={state.brand} onValueChange={onSetBrand} size={'xs'}>
          <Select.Item label="Hey Fella" value={'heyfella'} />
          <Select.Item label="Leaf Doctors" value={'leafdoctors'} />
          <Select.Item label="True Green" value={'truegreen'} />
          <Select.Item label="Online Doctor" value={'onlinedoctor'} />
          <Select.Item label="Doctor Portal" value={'portal'} />
        </Select>

        <Checkbox value={'dev'} isChecked={state.dev} onChange={(value) => onSetDev(value)}>
          <Text fontSize={'xs'} color={'black'}>
            Dev
          </Text>
        </Checkbox>
      </HStack>
    </>
  );
}
