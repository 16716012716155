import React from 'react';
import { useBrand, useBrandName } from '../../Common/hooks/useBrand';
const ProfileHeyFella = React.lazy(() => import('../..//Profile/views/ProfileHeyFella'));
const ProfileTrueGreen = React.lazy(
  () => import('../../Profile/components/TrueGreen/ProfileTrueGreen'),
);
const Profile = React.lazy(() => import('../../Profile/components/Profile'));

export function RequestWrapper({ children }: { children: React.ReactChildren }) {
  const brandName = useBrand();

  if (brandName === 'heyfella') {
    return <ProfileHeyFella>{children}</ProfileHeyFella>;
  }

  if (brandName === 'leafdoctors' || brandName === 'truegreen') {
    return <ProfileTrueGreen>{children}</ProfileTrueGreen>;
  }

  return <Profile>{children}</Profile>;
}
