import { ApolloClient, InMemoryCache } from '@apollo/client';

export const contentfulGQLClient = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/ovk33bfd5olz/environments/master`,
  cache: new InMemoryCache(),
  connectToDevTools: false,
  headers: {
    authorization: `Bearer LtB-CGDQxRHUicc-Us25HBpyB3A9LAMRMgyemDdUUVo`,
  },
});
