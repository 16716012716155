import { brandForDomain } from '../utils/brand';
import { extendTheme } from 'native-base';
import { Platform } from 'react-native';

const themeBrand = brandForDomain();

const gray100 = 'rgb(246,246,246)';

export const trueGreenBoxBG = '#00000073';
export const trueGreenLightgreen = '#03F17E';
export const trueGreenDarkgreen = '#03F17E';

export const theme = extendTheme({
  ...(themeBrand === 'onlinedoctor' && {
    fonts: {
      heading: 'DM Serif Display',
      body: 'DM Sans',
    },
    colors: {
      footerText: 'gray',
      footerLine: 'rgb(244, 244, 245)',
      headerBackground: '#f4f6fc',
      headerTitle: '#0c0890',
      openNow: '#16a34a',
      headerBackArrow: 'black',
      secureEncrypted: 'rgb(113, 113, 122)',
      servicesBackground: '#f4f6fc',
      title: '#0c0890',
      formHeader: 'black',
      formLabel: 'black',
      formHelp: 'rgb(113, 113, 122)',
      formOptionBGSelected: 'rgb(139, 215, 246)',
      formOptionBG: 'white',
      formOptionBorderSelected: 'rgb(139, 215, 246)',
      formOptionBorder: 'rgb(228, 228, 231)',
      formOptionText: 'black',
      formOptionTextSelected: 'black',
      loginBackground: 'rgb(250, 250, 250)',
      loginLabel: 'black',
      callOutBoxBg: gray100,
      callOutBoxText: 'black',
      headerAccount: 'rgb(82, 82, 91)',
      serviceBoxBackground: 'white',
      serviceBoxTitle: 'rgb(32, 51, 201)',
      serviceBoxText: 'black',
      serviceBoxPrice: 'rgb(82, 82, 91)',
      paymentBoxBackground: gray100,
      paymentBoxTitle: 'black',
      progressBarBackground: 'rgb(239, 246, 255)',
      progressBarFill: 'rgb(96, 165, 250)',
      progressBarText: 'black',
      requestHeaderBorder: 'rgb(212, 212, 216)',
      doctorAssignedBackground: gray100,
      doctorAssignedText: 'black',
      inProgressLabelBg: 'white',
      messagingBubble: '#0c79ec',
      messagingBubbleAlt: 'gray',
      messagingBubbleHeader: 'black',
      messagingBubbleText: 'white',
      messagingBubbleFooter: 'rgb(113, 113, 122)',
      loaderColor: 'black',
      paymentPointHighlights: '#0c0890',
      faqs: 'black',
      doctorOnlineColor: 'green',
      faqTitle: 'white',
    },
    components: {
      Button: {
        defaultProps: {
          variant: 'solid',
        },
        sizes: {
          md: {
            px: 10,
            py: 3,
          },
          sm: {
            px: 3,
          },
        },
        variants: {
          outline: {
            _text: {
              color: 'black',
              fontWeight: 600,
            },
          },
          form: {
            borderRadius: 'sm',
            size: 'lg',
            bg: 'rgb(32, 51, 201)',
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          solid: {
            borderRadius: 'sm',
            size: 'lg',
            bg: 'rgb(32, 51, 201)',
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          secondary: {
            borderRadius: 'sm',
            bg: 'rgb(139, 215, 246)',
            _text: {
              color: 'rgb(12, 8, 144)',
              fontWeight: 600,
            },
          },
        },
      },
      Text: {
        baseStyle: {
          color: 'black',
        },
      },
    },
  }),
  ...(themeBrand === 'heyfella' && {
    fonts: {
      heading: 'DM Serif Display',
      body: 'DM Sans',
    },
    colors: {
      stepBackground: 'white',
      footerText: 'black',
      footerLine: 'rgba(255,255,255,0.50)',
      headerBackground: trueGreenBoxBG,
      headerTitle: 'black',
      headerBackArrow: 'black',
      green: '#03F17E',
      openNow: 'green',
      secureEncrypted: '#33333',
      title: '#33333',
      formHeader: 'black',
      formLabel: 'white',
      formHelp: 'rgb(129,129,129)',
      formOptionBG: 'white',
      formOptionBGSelected: '#2853a9',
      formOptionBorder: 'gainsboro',
      formOptionBorderSelected: 'white',
      formOptionText: 'black',
      formOptionTextSelected: 'white',
      loginBackground: trueGreenBoxBG,
      loginLabel: 'white',
      callOutBoxBg: trueGreenBoxBG,
      callOutBoxText: 'white',
      headerAccount: '#33333',
      serviceBoxBackground: trueGreenBoxBG,
      serviceBoxTitle: 'white',
      serviceBoxText: 'white',
      serviceBoxPrice: 'white',
      paymentBoxBackground: trueGreenBoxBG,
      paymentBoxTitle: 'white',
      progressBarBackground: 'rgba(238,238,238,0.23)',
      progressBarFill: '#e7f1f1',
      progressBarText: 'black',
      schedulingTimeBg: trueGreenBoxBG,
      schedulingTimeText: 'white',
      schedulingTimeHeader: 'black',
      schedulingSelectedDateBorder: 'black',
      requestHeaderBorder: trueGreenBoxBG,
      doctorAssignedBackground: trueGreenBoxBG,
      doctorAssignedText: 'white',
      inProgressLabelBg: '#0000002e',
      stepIconColor: 'black',
      stepStatusButtonBackground: trueGreenBoxBG,
      stepStatusButtonTitle: 'black',
      messagingBubble: '#0c79ec',
      messagingBubbleAlt: '#0c79ec',

      messagingBubbleHeader: 'black',
      messagingBubbleText: 'white',
      messagingBubbleFooter: 'grey',

      loaderColor: 'black',
      stepColorBorder: 'black',
      stepPendingBg: '#f1c40f',
      stepDoneBg: '#2ecc71',
      doctorOnlineColor: 'green',
      heyFellaRed: '#ff3f21',
      heyFellaGreen: '#edffe8',
    },
    components: {
      Input: {
        defaultProps: {
          _focus: {
            bg: 'white',
          },
        },
      },
      Button: {
        defaultProps: {
          variant: 'solid',
        },
        sizes: {
          md: {
            px: 10,
            py: 3,
          },
          sm: {
            px: 3,
          },
        },
        variants: {
          outline: {
            _text: {
              color: '#33333',
              fontWeight: 600,
            },
          },
          solid: {
            borderRadius: 'sm',
            size: 'lg',
            bg: '#ff3f21',
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          secondary: {
            bg: '#00000080',
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          form: {
            borderRadius: 'sm',
            size: 'lg',
            bg: '#ff3f21',
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
        },
      },
      Text: {
        defaultProps: {
          color: '#333',
        },
      },
    },
  }),
  ...(themeBrand === 'truegreen' && {
    fonts: {
      heading: 'Cabin',
      body: 'Cabin',
    },
    colors: {
      footerText: 'white',
      footerLine: 'rgba(255,255,255,0.50)',
      headerBackground: trueGreenBoxBG,
      headerTitle: 'white',
      headerBackArrow: 'white',
      green: '#03F17E',
      openNow: 'white',
      secureEncrypted: 'white',
      title: 'white',
      formHeader: 'white',
      formLabel: 'white',
      formHelp: 'rgba(255,255,255,0.76)',
      formOptionBG: 'white',
      formOptionBGSelected: 'black',
      formOptionBorder: 'white',
      formOptionBorderSelected: '#black',
      formOptionText: 'black',
      formOptionTextSelected: 'white',
      loginBackground: trueGreenBoxBG,
      loginLabel: 'white',
      callOutBoxBg: trueGreenBoxBG,
      callOutBoxText: 'white',
      headerAccount: 'white',
      serviceBoxBackground: trueGreenBoxBG,
      serviceBoxTitle: 'white',
      serviceBoxText: 'white',
      serviceBoxPrice: 'white',
      paymentBoxBackground: trueGreenBoxBG,
      paymentBoxTitle: 'white',
      progressBarBackground: '#0000003b',
      progressBarFill: 'rgba(0,0,0,0.89)',
      progressBarText: 'white',
      schedulingTimeBg: trueGreenBoxBG,
      schedulingTimeText: 'white',
      schedulingTimeHeader: 'white',
      schedulingSelectedDateBorder: 'white',
      requestHeaderBorder: trueGreenBoxBG,
      doctorAssignedBackground: trueGreenBoxBG,
      doctorAssignedText: 'white',
      inProgressLabelBg: '#0000002e',
      stepIconColor: 'white',
      stepStatusButtonBackground: trueGreenBoxBG,
      stepStatusButtonTitle: 'white',
      messagingBubble: 'white',
      messagingBubbleText: 'black',
      messagingBubbleHeader: '#ffffff91',
      stepPendingBg: '#00000073',
      stepDoneBg: '#00000073',
      loaderColor: 'white',
    },
    components: {
      Input: {
        defaultProps: {
          _focus: {
            bg: 'white',
          },
        },
      },
      Button: {
        defaultProps: {
          variant: 'solid',
        },
        sizes: {
          md: {
            px: 10,
            py: 3,
          },
          sm: {
            px: 3,
          },
        },
        variants: {
          outline: {
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          solid: {
            borderRadius: 'sm',
            size: 'lg',
            bg: '#03F17E',
            _text: {
              color: '#000000',
              fontWeight: 600,
            },
          },
          secondary: {
            bg: '#00000080',
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          form: {
            borderRadius: 'sm',
            size: 'lg',
            bg: '#03F17E',
            _text: {
              color: 'black',
              fontWeight: 600,
            },
          },
        },
      },
      Text: {
        defaultProps: {
          color: 'white',
        },
      },
    },
  }),
  ...(themeBrand === 'truegreen' && {
    fonts: {
      heading: 'Cabin',
      body: 'Cabin',
    },
    colors: {
      footerText: 'white',
      footerLine: 'rgba(255,255,255,0.50)',
      headerBackground: trueGreenBoxBG,
      headerTitle: 'white',
      headerBackArrow: '#333333',
      green: '#03F17E',
      openNow: '#333333',
      doctorOnlineColor: 'green',
      secureEncrypted: 'white',
      title: 'white',
      formHeader: 'white',
      formLabel: 'white',
      formHelp: 'rgba(255,255,255,0.76)',
      formOptionBG: 'white',
      formOptionBGSelected: 'black',
      formOptionBorder: 'white',
      formOptionBorderSelected: '#black',
      formOptionText: 'black',
      formOptionTextSelected: 'white',
      loginBackground: trueGreenBoxBG,
      loginLabel: 'white',
      callOutBoxBg: trueGreenBoxBG,
      callOutBoxText: 'white',
      headerAccount: '#333333',
      serviceBoxBackground: trueGreenBoxBG,
      serviceBoxTitle: 'white',
      serviceBoxText: 'white',
      serviceBoxPrice: 'white',
      paymentBoxBackground: trueGreenBoxBG,
      paymentBoxTitle: 'white',
      progressBarBackground: '#0000003b',
      progressBarFill: 'rgba(0,0,0,0.89)',
      progressBarText: 'white',
      schedulingTimeBg: trueGreenBoxBG,
      schedulingTimeText: 'white',
      schedulingTimeHeader: 'white',
      schedulingSelectedDateBorder: 'white',
      requestHeaderBorder: trueGreenBoxBG,
      doctorAssignedBackground: trueGreenBoxBG,
      doctorAssignedText: 'white',
      inProgressLabelBg: '#0000002e',
      stepIconColor: 'white',
      stepStatusButtonBackground: trueGreenBoxBG,
      stepStatusButtonTitle: '#ffdd2e',
      stepStatusButtonTitleCompleted: trueGreenLightgreen,
      messagingBubble: 'white',
      messagingBubbleAlt: 'rgba(255,255,255,0.82)',
      messagingBubbleText: 'black',
      loaderColor: 'white',
      paymentPointHighlights: 'white',

      stepPendingBg: '#00000073',
      stepCompleteBg: '#00000073',
      stepColorBorder: 'white',
      headerHelp: 'black',
      messagingBubbleHeader: 'white',
      messagingBubbleFooter: 'rgb(113, 113, 122)',

      faqTitle: 'white',
      faqs: 'black',
    },
    components: {
      Input: {
        defaultProps: {
          _focus: {
            bg: 'white',
          },
        },
      },
      Button: {
        defaultProps: {
          variant: 'solid',
        },
        sizes: {
          md: {
            px: 10,
            py: 3,
          },
          sm: {
            px: 3,
          },
        },
        variants: {
          outline: {
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          solid: {
            borderRadius: 'sm',
            size: 'lg',
            bg: '#03F17E',
            _text: {
              color: '#000000',
              fontWeight: 600,
            },
          },
          secondary: {
            bg: '#00000080',
            _text: {
              color: 'white',
              fontWeight: 600,
            },
          },
          form: {
            borderRadius: 'sm',
            size: 'lg',
            bg: '#03F17E',
            _text: {
              color: 'black',
              fontWeight: 600,
            },
          },
        },
      },
      Text: {
        defaultProps: {
          color: 'white',
        },
      },
    },
  }),

  ...(themeBrand === 'leafdoctors' && {
    fonts: {
      heading: 'Dinamit Headings',
      body: 'Bastardo Body Copy',
    },
    typography: {
      '2xs': 10,
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
      '2xl': 24,
      '3xl': 30,
      '4xl': 36,
      '5xl': 48,
      '6xl': 60,
      '7xl': 72,
      '8xl': 96,
      '9xl': 128,
    },
    colors: {
      footerText: 'gray',
      footerLine: 'rgb(244, 244, 245)',
      headerBackground: '#f4f6fc',
      headerTitle: '#0c0890',
      openNow: '#16a34a',
      headerBackArrow: 'black',
      secureEncrypted: 'rgb(113, 113, 122)',
      servicesBackground: '#f4f6fc',
      title: '#0c0890',
      formHeader: 'black',
      formLabel: 'black',
      formHelp: 'black',
      formOptionBGSelected: '#123b36',
      formOptionBG: 'white',
      formOptionBorderSelected: '#123b36',
      formOptionBorder: 'rgb(228, 228, 231)',
      formOptionText: 'black',
      formOptionTextSelected: 'white',
      loginBackground: 'rgb(250, 250, 250)',
      loginLabel: 'black',
      callOutBoxBg: gray100,
      callOutBoxText: 'black',
      headerAccount: 'rgb(82, 82, 91)',
      serviceBoxBackground: 'white',
      serviceBoxTitle: '#66462C',
      serviceBoxText: 'black',
      serviceBoxPrice: 'rgb(82, 82, 91)',
      paymentBoxBackground: '#f7f5ed',
      paymentBoxTitle: 'black',
      progressBarBackground: 'rgb(239, 246, 255)',
      progressBarFill: 'rgb(96, 165, 250)',
      progressBarText: 'black',
      requestHeaderBorder: 'rgb(212, 212, 216)',
      doctorAssignedBackground: gray100,
      doctorAssignedText: 'black',
      inProgressLabelBg: 'white',
      messagingBubble: '#0c79ec',
      messagingBubbleAlt: 'gray',
      messagingBubbleHeader: 'black',
      messagingBubbleText: 'white',
      messagingBubbleFooter: 'rgb(113, 113, 122)',
      loaderColor: '#333333',
      paymentPointHighlights: '#66462C',
      faqs: 'black',
      doctorOnlineColor: 'green',
      faqTitle: 'white',
    },
    components: {
      Button: {
        defaultProps: {
          variant: 'solid',
        },
        sizes: {
          md: {
            px: 10,
            py: 3,
          },
          sm: {
            px: 3,
          },
        },
        variants: {
          outline: {
            borderRadius: '3xl',
            size: 'lg',
            bg: null,
            borderColor: 'black',
            _text: {
              fontFamily: 'Tiemposfine Subheadings',
              color: 'black',
              fontSize: '16px',
            },
          },
          form: {
            borderRadius: '3xl',
            size: 'lg',
            bg: 'black',
            _text: {
              fontFamily: 'Tiemposfine Subheadings',
              color: 'white',
              fontSize: '16px',
            },
          },
          solid: {
            borderRadius: '3xl',
            size: 'lg',
            bg: 'black',
            _text: {
              fontFamily: 'Tiemposfine Subheadings',
              color: 'white',
              fontSize: '16px',
            },
          },
          secondary: {
            borderRadius: '3xl',
            size: 'lg',
            bg: 'black',
            _text: {
              fontFamily: 'Tiemposfine Subheadings',
              color: 'white',
              fontSize: '16px',
            },
          },
        },
      },

      Text: {
        baseStyle: {
          color: 'black',
          fontSize: 'md',
        },
      },
    },
  }),
});
