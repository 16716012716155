import { devStateLocalStorageKey } from '../../DevSwitcher/state/devState';

export const isDoctorPortal = () => {
  if (window.location.hostname === 'localhost') {
    const localStorageData = localStorage.getItem(devStateLocalStorageKey);

    if (localStorageData) {
      const { brand } = JSON.parse(localStorageData);

      return brand === 'portal';
    }
  }

  return (
    window.location.hostname.indexOf('portal.onlinedoctor.clinic') > -1 ||
    window.location.hostname.indexOf('dev-portal.onlinedoctor.clinic') > -1 ||
    window.location.hostname.indexOf('dev-portal-local.onlinedoctor.clinic') > -1
  );
};
