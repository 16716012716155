import { useNavigate, useParams } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useRouting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  return {
    navigate,
    params,
    pathname,
    setSearchParams,
    searchParams,
  };
};
