import {useBrandName} from "./useBrand";
import {useIsDev} from "./useIsDev";

export const isBrandsPaymentLive = () => {
    const { live: brandLive } = useBrandName();
    const dev = useIsDev();

    if (dev) return false;

    return brandLive;
}
