import React, { memo } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { useBrand } from '../hooks/useBrand';
import { LoadingHeyFella } from './LoadingHeyFella';
import { LoadingLeafDoctors } from './LoadingLeafDoctors';

const Loading = ({ backgroundColor }: { backgroundColor?: string }) => {
  const brand = useBrand();

  const getElement = () => {
    if (brand === 'heyfella') return <LoadingHeyFella />;
    if (brand === 'leafdoctors') return <LoadingLeafDoctors />;

    return <ActivityIndicator size={'large'} />;
  };

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: backgroundColor,
      }}
    >
      {getElement()}
    </View>
  );
};

export default memo(Loading);
