import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router';
import { BrowserRouter, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { useCustomer } from './Common/hooks/useCustomer';
import WebLoader from './WebLoader';
import { useIsMobile } from './Common/hooks/useIsMobile';
import { useLiveChat } from './Common/hooks/useLiveChat';
import { useBrand, useBrandName } from './Common/hooks/useBrand';
import { useServices } from './Services/hooks/useServices';
import { usePaymentsApplePaySupport } from './Payment/hooks/usePaymentsApplePay';
import { isDoctorPortal } from './Common/utils/isDoctorPortal';
import { BannerBox } from './Common/components/Banner';
import DocumentTitle from 'react-document-title';
import { RequestWrapper } from './Request/components/RequestWrapper';

const PortalHeader = React.lazy(() => import('./Header/PortalHeader'));

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const MedicalInformation = React.lazy(() =>
  import('./MedicalInformation/components/MedicalInformation'),
);
const Profile = React.lazy(() => import('./Profile/components/Profile'));
const DoctorRequest = React.lazy(() => import('./Request/components/DoctorRequest'));
const DoctorList = React.lazy(() => import('./DoctorList/DoctorList'));
const Request = React.lazy(() => import('./Request/components/Request'));
const Payment = React.lazy(() => import('./Payment/components/Payment'));
const Start = React.lazy(() => import('./Start/Start'));
const Info = React.lazy(() => import('./Info/components/Info'));
const Login = React.lazy(() => import('./Login/components/Login'));
const DoctorLogin = React.lazy(() => import('./Login/components/DoctorLogin'));
const Services = React.lazy(() => import('./Services/components/Services'));
const Contact = React.lazy(() => import('./Contact/components/Contact'));
const PrivacyPolicy = React.lazy(() => import('./Legal/PrivacyPolicy'));
const TermsOfUse = React.lazy(() => import('./Legal/TermsOfUse'));
const AppointmentBookingFlow = React.lazy(() =>
  import('./AppointmentBooking/components/FlowBooking'),
);
const MedCert = React.lazy(() => import('./OnlineDoctor/MedCert/MedCert'));
const Home = React.lazy(() => import('./Home/Home'));
const NotFound = React.lazy(() => import('./NotFound/components/NotFound'));
const HomeTrueGreen = React.lazy(() => import('./Home/HomeTrueGreen'));
const PageForm = React.lazy(() => import('./Form/components/PageForm'));
const PageBooking = React.lazy(() => import('./AppointmentBooking/components/PageBooking'));
const ProfileHeyFella = React.lazy(() => import('./Profile/views/ProfileHeyFella'));
const ProfileOverview = React.lazy(() => import('./Profile/views/Overview'));
const ProfileMessages = React.lazy(() => import('./Profile/views/Messages'));
const ProfileRequests = React.lazy(() => import('./Profile/views/Requests'));
const ProfileRefer = React.lazy(() => import('./Profile/views/Refer'));

const LeafDoctorsSignup = React.lazy(() => import('./LeafDoctors/Signup/Signup'));

const ProfileHealth = React.lazy(() => import('./Profile/views/Health'));
const ProfilePathologyResult = React.lazy(() =>
  import('./HeyFellaMobileApp/Requests/views/PathologyRequest'),
);

const ProfileScript = React.lazy(() => import('./HeyFellaMobileApp/Requests/views/Script'));

const Mediextract = React.lazy(() => import('./Mediextract/components/Mediextract'));
const ProfileTrueGreen = React.lazy(() =>
  import('./Profile/components/TrueGreen/ProfileTrueGreen'),
);
const UpdateProfile = React.lazy(() => import('./Profile/components/ProfileDetails'));
const SubscribeFlow = React.lazy(() => import('./Subscribe/components/Subscribe'));
const DoctorAppointments = React.lazy(() =>
  import('./DoctorAppointments/components/DoctorAppointments'),
);
const ProfileTrueGreenTreatmentPlan = React.lazy(() =>
  import('./Profile/components/TrueGreen/TreatmentPlan'),
);
const ProfileTrueGreenMessaging = React.lazy(() =>
  import('./Profile/components/TrueGreen/Messaging'),
);
const ProfileTrueGreenOrder = React.lazy(() => import('./Profile/components/TrueGreen/Order'));
const ProfileTrueGreenAppointments = React.lazy(() =>
  import('./Profile/components/TrueGreen/Appointments'),
);
const OnlineDoctorOurDoctors = React.lazy(() => import('./OnlineDoctor/OurDoctors/OurDoctors'));
const OnlineDoctorAbout = React.lazy(() => import('./OnlineDoctor/AboutUs/AboutUs'));
const OnlineDoctorRepeatPrescription = React.lazy(() =>
  import('./OnlineDoctor/RepeatScript/RepeatScript'),
);
const OnlineDoctorTelehealthConsultation = React.lazy(() =>
  import('./OnlineDoctor/TelehealthConsultation/TelehealthConsultation'),
);
const OnlineDoctorCondition = React.lazy(() => import('./OnlineDoctor/Conditions/Condition'));
const OnlineDoctorAtWork = React.lazy(() => import('./OnlineDoctor/AtWork/AtWork'));
const OnlineDoctorWorkWithUs = React.lazy(() => import('./OnlineDoctor/WorkWithUs/WorkWithUs'));
const OnlineDoctorMedCertVerify = React.lazy(() => import('./OnlineDoctor/MedCert/MedCertVerify'));
const OnlineDoctorMedCertSample = React.lazy(() => import('./OnlineDoctor/MedCert/MedCertSample'));
const CustomerList = React.lazy(() => import('./CustomerList/components/CustomerList'));
const DoctorHome = React.lazy(() => import('./DoctorHome/components/DoctorHome'));
const DoctorCustomer = React.lazy(() => import('./DoctorCustomer/components/DoctorCustomer'));
const DoctorUnread = React.lazy(() =>
  import('./DoctorUnreadMessages/components/DoctorUnreadMessages'),
);
const DoctorUnreviewedResults = React.lazy(() => import('./DoctorPathology/views/Results'));
const DoctorPathologyResult = React.lazy(() => import('./DoctorPathology/views/Result'));
const DoctorScheduling = React.lazy(() => import('./DoctorScheduling/views/DoctorScheduling'));
const DoctorPathologyRequests = React.lazy(() => import('./DoctorPathology/views/Requests'));
const DoctorPathologyRequest = React.lazy(() => import('./DoctorPathology/views/Request'));

const PrescriptionsToFulfill = React.lazy(() => import('./Fufillment/views/PrescriptionToFulfill'));

function AuthProtectedRoute({ children }) {
  const { signedin, loading } = useCustomer();

  const { pathname } = useLocation();

  if (loading) return <WebLoader />;

  if (!signedin) {
    return <Navigate to={`/signin?redirect=${pathname}`} replace />;
  }

  return children || <Outlet />;
}

const doctorPortal = isDoctorPortal();

function WebApp({ authenticated }) {
  const { isMobile } = useIsMobile();

  useLiveChat();

  useServices();

  usePaymentsApplePaySupport();

  const { name } = useBrandName();

  const brand = useBrand();

  return (
    <>
      {doctorPortal && <PortalHeader />}

      <Suspense fallback={<WebLoader />}>
        <BannerBox />

        <DocumentTitle title={name} />

        <Routes>
          {!doctorPortal && brand === 'onlinedoctor' && (
            <>
              <Route element={<Home />} path="/" />

              <Route element={<MedCert />} path="/medical-certificates" />
              <Route element={<OnlineDoctorMedCertVerify />} path="/medical-certificates/verify" />
              <Route element={<OnlineDoctorMedCertSample />} path="/medical-certificates/sample" />
              <Route element={<OnlineDoctorMedCertVerify />} path="/medical-certificate/verify" />

              <Route
                element={
                  <AuthProtectedRoute>
                    <Profile />
                  </AuthProtectedRoute>
                }
                path="/profile"
              />

              <Route element={<OnlineDoctorOurDoctors />} path={'/our-partner-doctors'} />
              <Route element={<OnlineDoctorAbout />} path={'/about-us'} />
              <Route element={<OnlineDoctorAtWork />} path={'/at-work'} />
              <Route element={<OnlineDoctorRepeatPrescription />} path={'/repeat-prescription'} />
              <Route
                element={<OnlineDoctorTelehealthConsultation />}
                path={'/telehealth-consultation'}
              />
              <Route element={<OnlineDoctorCondition />} path={'/conditions/:condition'} />
              <Route element={<OnlineDoctorWorkWithUs />} path={'/work-with-us'} />
            </>
          )}

          {!doctorPortal && brand === 'heyfella' && (
            <>
              <Route
                element={
                  <AuthProtectedRoute>
                    <ProfileHeyFella />
                  </AuthProtectedRoute>
                }
                path="/profile"
              >
                <Route element={<ProfileHealth />} path={'health'} />

                <Route element={<UpdateProfile wrap={false} />} path="info" />

                <Route element={<ProfileMessages />} path={'messages'} />

                <Route element={<ProfileRequests />} path={'requests'} />

                <Route element={<ProfileRefer />} path={'refer'} />

                <Route element={<ProfilePathologyResult />} path={'health/pathology/:requestID'} />

                <Route element={<ProfileScript />} path={'health/script/:scriptID'} />

                <Route element={<ProfileOverview />} path={''} />
              </Route>
            </>
          )}

          {!doctorPortal && brand === 'leafdoctors' && (
            <>
              <Route element={<LeafDoctorsSignup />} path={'/signup'} />

              <Route
                element={
                  <AuthProtectedRoute>
                    <ProfileTrueGreen />
                  </AuthProtectedRoute>
                }
                path="/profile"
              >
                <Route element={<ProfileTrueGreenTreatmentPlan />} path={''} />
                <Route element={<ProfileTrueGreenMessaging />} path={'messages'} />
                <Route element={<ProfileTrueGreenOrder />} path={'order'} />
                <Route element={<ProfileTrueGreenAppointments />} path={'appointments'} />
              </Route>
            </>
          )}

          {!doctorPortal && brand === 'truegreen' && (
            <>
              <Route element={<HomeTrueGreen />} path="/" />

              <Route element={<Mediextract />} path="/mediextract" />

              <Route
                element={
                  <AuthProtectedRoute>
                    <ProfileTrueGreen />
                  </AuthProtectedRoute>
                }
                path="/profile"
              >
                <Route element={<ProfileTrueGreenTreatmentPlan />} path={''} />
                <Route element={<ProfileTrueGreenMessaging />} path={'messages'} />
                <Route element={<ProfileTrueGreenAppointments />} path={'appointments'} />
              </Route>
            </>
          )}

          <Route element={isDoctorPortal() ? <DoctorLogin /> : <Login />} path="/signin" />

          {!doctorPortal && (
            <>
              <Route element={<Contact />} path="/contact" />

              <Route element={<Services />} path="/services" />

              <Route element={<TermsOfUse />} path="/terms-of-use" />

              <Route element={<PrivacyPolicy />} path="/privacy-policy" />

              <Route element={<Start />} path="/start" />

              <Route element={<Info />} path="/start/info" />

              <Route element={<Info />} path="/start/info" />

              <Route element={<AppointmentBookingFlow />} path="/start/schedule" />

              <Route element={<MedicalInformation />} path="/start/medical" />

              <Route element={<Payment />} path="/start/payment" />

              <Route
                element={
                  <AuthProtectedRoute>
                    <RequestWrapper>
                      <Request />
                    </RequestWrapper>
                  </AuthProtectedRoute>
                }
                path="/request/:requestID"
              />

              <Route
                element={
                  <AuthProtectedRoute>
                    <PageBooking />
                  </AuthProtectedRoute>
                }
                path="/book/:requestID"
              />

              <Route
                element={
                  <AuthProtectedRoute>
                    <PageForm />
                  </AuthProtectedRoute>
                }
                path="/form/:formID/:requestID"
              />

              {brand !== 'heyfella' && (
                <Route
                  element={
                    <AuthProtectedRoute>
                      <UpdateProfile />
                    </AuthProtectedRoute>
                  }
                  path="/profile/update"
                />
              )}
            </>
          )}

          {!authenticated && doctorPortal && (
            <>
              <Route path="*" element={<Navigate replace to="/signin?redirect=/" />} />
            </>
          )}

          {authenticated && doctorPortal && (
            <>
              <Route element={<DoctorHome />} path="/" />

              <Route element={<DoctorScheduling />} path="/scheduling" />

              <Route element={<DoctorUnread />} path="/unread-messages" />

              <Route element={<DoctorAppointments />} path="/appointments" />

              <Route element={<PrescriptionsToFulfill />} path="/fulfill" />

              <Route element={<CustomerList />} path="/customers" />

              <Route element={<DoctorCustomer />} path="/customers/:customerID" />

              {!isMobile && (
                <Route element={<DoctorUnreviewedResults />} path="/pathology/results">
                  <Route element={<DoctorPathologyResult />} path="/pathology/results/:resultID" />
                </Route>
              )}

              {!isMobile && (
                <Route element={<DoctorPathologyRequests />} path="/pathology/requests">
                  <Route
                    element={<DoctorPathologyRequest />}
                    path="/pathology/requests/:requestID"
                  />
                </Route>
              )}

              {isMobile && (
                <>
                  <Route element={<DoctorUnreviewedResults />} path="/pathology/results" />
                  <Route element={<DoctorPathologyResult />} path="/pathology/results/:resultID" />
                </>
              )}

              <Route element={<DoctorRequest />} path="/request/:brand/:requestID" />

              {!isMobile && (
                <Route element={<DoctorList />} path="/requests/:brand">
                  <Route element={<DoctorRequest />} path="/requests/:brand/:requestID" />
                </Route>
              )}

              {isMobile && (
                <>
                  <Route element={<DoctorList />} path="/requests/:brand" />

                  <Route element={<DoctorRequest />} path="/requests/:brand/:requestID" />
                </>
              )}
            </>
          )}

          <Route path="/404" element={<NotFound />} />

          {brand === 'leafdoctors' || brand === 'heyfella' ? (
            <Route path="*" element={<Navigate replace to="/services" />} />
          ) : (
            <Route path="*" element={<Navigate replace to="/404" />} />
          )}
        </Routes>
      </Suspense>
    </>
  );
}

export default WebApp;
