import { gql, useQuery } from '@apollo/client';
import { useBrand } from '../../Common/hooks/useBrand';

export const useServices = () => {
  const brand = useBrand();

  const { data, loading } = useQuery(
    gql`
      query services($brand: String) {
        services(brand: $brand) {
          name
          id
          description
          pricing
          order
          active
          fields
          upfront
          availableToUser
          options {
            id
            price
          }
          locales {
            key
            value
          }
        }
      }
    `,
    {
      variables: {
        brand: brand,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  return {
    loading,
    services: data?.services ?? [],
  };
};
