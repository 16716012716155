import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { HeyFellaBadge } from '../../HeyFellaMobileApp/Common/HeyFellaBadge';
import React, { useEffect } from 'react';
import { Box, Text } from 'native-base';

export const LoadingHeyFella = () => {
  const y1 = useSharedValue(0);

  const animatedStyles1 = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withDelay(0, withRepeat(withTiming(y1.value, { duration: 250 }), -1, true)),
        },
      ],
    };
  }, []);

  useEffect(() => {
    y1.value = -10;
  }, []);

  return (
    <Animated.View style={[animatedStyles1]}>
      <Box p={4}>
        <HeyFellaBadge h={10} w={10} />

        <Text mt={4} color={'#919191'}>
          Loading..
        </Text>
      </Box>
    </Animated.View>
  );
};
