import * as Sentry from "@sentry/browser";

export const useError = () => {
    const handleError = (e, data) => {
        console.error(e, data);

        Sentry.captureException(e, data);
    }

    return {
        handleError
    }
}
