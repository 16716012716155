import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { Text, Button, Modal, NativeBaseProvider, VStack } from 'native-base';
import auth0 from 'auth0-js';
import { RecoilRoot, atom, useRecoilState } from 'recoil';
import WebApp from './WebApp/WebApp';
import * as Sentry from '@sentry/react';
import { brandForDomain } from './WebApp/Common/utils/brand';
import { theme } from './WebApp/Common/theme/ThemeProvider';
import { useBrandName } from './WebApp/Common/hooks/useBrand';
import WebLoader from './WebApp/WebLoader';
import { isDev } from './WebApp/Common/hooks/useIsDev';
import { loadEwayECrypt } from './WebApp/Common/utils/eway';
import { onError } from '@apollo/client/link/error';
import { isDoctorPortal } from './WebApp/Common/utils/isDoctorPortal';
import { View } from 'react-native';
import { BrowserRouter } from 'react-router-dom';
import { DevSwitcher } from './WebApp/DevSwitcher/components/DevSwitcher';
import { devStateIsLocalHost } from './WebApp/DevSwitcher/state/devState';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

loadEwayECrypt();

import { AwsRum } from 'aws-rum-web';

const loadScripts = document?.location?.hostname?.indexOf('localhost') < 0;

const useNewAuth = isDoctorPortal();

if (loadScripts) {
  const { gtm, cio } = useBrandName();

  if (!isDoctorPortal()) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtm);

    (function () {
      var a, b, c;
      a = function (f) {
        return function () {
          _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      b = ['load', 'identify', 'sidentify', 'track', 'page'];
      for (c = 0; c < b.length; c++) {
        _cio[b[c]] = a(b[c]);
      }
      var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.setAttribute('data-auto-track-page', 'false');
      t.id = 'cio-tracker';
      t.setAttribute('data-site-id', cio);
      t.src = 'https://assets.customer.io/assets/track.js';
      s.parentNode.insertBefore(t, s);
    })();
  }

  Sentry.init({
    dsn: 'https://739eef624f03449694da7e4d12b7aea5@o1123776.ingest.sentry.io/4505339320664064',
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [
          'https://api.onlinedoctor.clinic/graphql',
          'https://api.truegreen.health/graphql',
          'https://api.heyfella.com.au/graphql',
          'https://api.leafdoctors.com.au/graphql',
          'https://api-portal.onlinedoctor.clinic/graphql',
        ],
        networkCaptureBodies: false,
        maskAllText: false,
      }),
    ],
    enabled: true,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const brand = brandForDomain();

  const config = () => {
    if (brand === 'heyfella') {
      return {
        applicationID: '97cacd5b-babd-49c3-9301-6a6fe57b3633',
        config: {
          sessionSampleRate: 1,
          guestRoleArn:
            'arn:aws:iam::718302092024:role/RUM-Monitor-ap-southeast-2-718302092024-7700049064071-Unauth',
          identityPoolId: 'ap-southeast-2:133d2da5-fc57-43ff-abdc-e624c5f4498e',
          endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
          telemetries: [
            'performance',
            'errors',
            [
              'http',
              {
                addXRayTraceIdHeader: true,
                recordAllRequests: true,
                urlsToInclude: [
                  /^https:\/\/api\.heyfella\.com\.au\/.*/,
                  /^https:\/\/patient\.heyfella\.com\.au\/.*/,

                  /^https:\/\/api-dev\.heyfella\.com\.au\/.*/,
                  /^https:\/\/patient-dev\.heyfella\.com\.au\/.*/,
                ],
              },
            ],
          ],
          allowCookies: true,
          enableXRay: true,
        },
      };
    }

    if (brand === 'leafdoctors') {
      return {
        applicationID: '44af9a9a-144d-42d0-8a78-a3761c21a0db',
        config: {
          sessionSampleRate: 1,
          guestRoleArn:
            'arn:aws:iam::718302092024:role/RUM-Monitor-ap-southeast-2-718302092024-1332744264071-Unauth',
          identityPoolId: 'ap-southeast-2:5ff76657-40b8-43d2-85ac-d0d23a6a6d4a',
          endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
          telemetries: [
            'performance',
            'errors',
            [
              'http',
              {
                addXRayTraceIdHeader: true,
                recordAllRequests: true,
                urlsToInclude: [
                  /^https:\/\/api\.leafdoctors\.com\.au\/.*/,
                  /^https:\/\/patient\.leafdoctors\.com\.au\/.*/,

                  /^https:\/\/api-dev\.leafdoctors\.com\.au\/.*/,
                  /^https:\/\/patient-dev\.leafdoctors\.com\.au\/.*/,
                ],
              },
            ],
          ],
          allowCookies: true,
          enableXRay: true,
        },
      };
    }

    if (isDoctorPortal()) {
      return {
        applicationID: '4d22e6f1-4ca4-432f-a5e9-b3bc45c878a6',
        config: {
          sessionSampleRate: 1,
          guestRoleArn:
            'arn:aws:iam::718302092024:role/RUM-Monitor-ap-southeast-2-718302092024-5734827534071-Unauth',
          identityPoolId: 'ap-southeast-2:36efe48d-7ad5-4136-9761-e819a9b47d78',
          endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
          telemetries: [
            'performance',
            'errors',
            [
              'http',
              {
                addXRayTraceIdHeader: true,
                recordAllRequests: true,
                urlsToInclude: [
                  /^https:\/\/api-portal-dev\.onlinedoctor\.clinic\/.*/,
                  /^https:\/\/dev-portal\.onlinedoctor\.clinic\/.*/,

                  /^https:\/\/portal\.onlinedoctor\.clinic\/.*/,
                  /^https:\/\/api-portal\.onlinedoctor\.clinic\/.*/,
                ],
              },
            ],
          ],
          allowCookies: true,
          enableXRay: true,
        },
      };
    }

    if (brand === 'onlinedoctor') {
      return {
        applicationID: '45976d4a-0dec-40ed-b053-74aa10eeb029',
        config: {
          sessionSampleRate: 1,
          guestRoleArn:
            'arn:aws:iam::718302092024:role/RUM-Monitor-ap-southeast-2-718302092024-8687314764071-Unauth',
          identityPoolId: 'ap-southeast-2:145df30b-b2a2-4207-a74a-714272e1a3a2',
          endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
          telemetries: [
            'performance',
            'errors',
            [
              'http',
              {
                addXRayTraceIdHeader: true,
                recordAllRequests: true,
                urlsToInclude: [
                  /^https:\/\/api-dev\.onlinedoctor\.clinic\/.*/,
                  /^https:\/\/dev\.onlinedoctor\.clinic\/.*/,

                  /^https:\/\/onlinedoctor\.clinic\/.*/,
                  /^https:\/\/api\.onlinedoctor\.clinic\/.*/,
                ],
              },
            ],
          ],
          allowCookies: true,
          enableXRay: true,
        },
      };
    }

    return null;
  };

  const rumConfig = config();

  if (rumConfig) {
    window.awsRum = new AwsRum(
      rumConfig.applicationID,
      __VERSION__,
      'ap-southeast-2',
      rumConfig.config,
    );
  }
}

const apiURLForBrand = () => {
  const brand = brandForDomain();
  const hostname = () => {
    if (isDoctorPortal()) {
      return isDev() ? 'api-portal-dev' : 'api-portal';
    }

    return isDev() ? 'api-dev' : 'api';
  };

  if (brand === 'heyfella') return `https://${hostname()}.heyfella.com.au/graphql`;
  if (brand === 'truegreen') return `https://${hostname()}.truegreen.health/graphql`;
  if (brand === 'leafdoctors') return `https://${hostname()}.leafdoctors.com.au/graphql`;

  return `https://${hostname()}.onlinedoctor.clinic/graphql`;
};

const apiURL = apiURLForBrand();

if (brandForDomain() === 'truegreen') {
  document.body.style.background = '#5115C6';

  const head = document.getElementsByTagName('head')[0];
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.media = 'print';
  link.onload = (e) => (link.media = 'all');
  link.href = `https://fonts.googleapis.com/css2?family=${useBrandName().font}&display=swap`;
  head.appendChild(link);
} else if (brandForDomain() === 'heyfella') {
  document.body.style.background = '#edffe8';

  const head = document.getElementsByTagName('head')[0];

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.media = 'print';
  link.onload = (e) => (link.media = 'all');
  link.href = `https://fonts.googleapis.com/css2?family=${useBrandName().font}&display=swap`;
  head.appendChild(link);
} else if (brandForDomain() === 'leafdoctors') {
  document.body.style.background = '#d9d1bf';

  const head = document.getElementsByTagName('head')[0];

  var style = document.createElement('style');
  style.innerHTML = `
  @font-face {
    font-family: 'Holo Mono Ctas';
    src: url('https://uploads-ssl.webflow.com/657284098041221366af2a16/6583aa835151edd7c6722584_Holo_Mono_Regular_CTAs.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bastardo Body Copy';
    src: url('https://uploads-ssl.webflow.com/657284098041221366af2a16/6583aa8422edf19691fa5863_Bastardo_Light_Body%20Copy.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dinamit Headings';
    src: url('https://uploads-ssl.webflow.com/657284098041221366af2a16/6583aa83fc1cfcb0e1780832_Dinamit_Regular_Headings.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tiemposfine Subheadings';
    src: url('https://uploads-ssl.webflow.com/657284098041221366af2a16/6583aa830693f08aab156264_TiemposFine_LightItalic_Subheadings.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
`;
  head.appendChild(style);
} else {
  const head = document.getElementsByTagName('head')[0];

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.media = 'print';
  link.onload = (e) => (link.media = 'all');
  link.href = `https://fonts.googleapis.com/css2?family=${useBrandName().font}&display=swap`;
  head.appendChild(link);
}

// luke@91developments.com

function configForBrand() {
  const brand = brandForDomain();

  if (isDoctorPortal()) {
    return {
      domain: 'auth-portal.onlinedoctor.clinic',
      audience: 'https://api.onlinedoctor.clinic',
      clientID: 'kFb1fTVeeLefXhlxU3pv6Z4WZIAkJHEm',
    };
  }

  if (brand === 'heyfella') {
    return {
      domain: 'auth.heyfella.com.au',
      audience: 'https://api.heyfella.com.au',
      clientID: 'oTYBMaYuf6UzVRb6H7TsRWcygV5kSOvJ',
    };
  }

  if (brand === 'truegreen') {
    return {
      domain: 'auth.truegreen.health',
      audience: 'https://api.truegreen.health',
      clientID: 'learfFr5LgO9Qn53vdRxZc6THhmCVyOy',
    };
  }

  if (brand === 'leafdoctors') {
    return {
      domain: 'auth.leafdoctors.com.au',
      audience: 'https://api.leafdoctors.com.au',
      clientID: 'lHESycyUWH0rWArybLI9rdmMhS1Xsw5R',
    };
  }

  return {
    domain: 'auth.onlinedoctor.clinic',
    audience: 'https://api.onlinedoctor.clinic',
    clientID: '9h6TsR4fVfQ4cRtk8n1a6NncFf66u3GX',
  };
}

const redirectUri =
  window.location.hostname === 'localhost'
    ? `http://localhost:19006/auth-callback`
    : `https://${window.location.hostname}/auth-callback`;

export const webAuth = new auth0.WebAuth({
  ...configForBrand(),
  responseType: 'token',
  grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
  redirectUri,
});

export const authenticationStateAtom = atom({
  key: 'authentication',
  default: { authenticated: false, accessToken: null, loading: true },
});

function AppWeb() {
  const [authenticationState, setAuthenticationState] = useRecoilState(authenticationStateAtom);

  useEffect(() => {
    webAuth.checkSession({}, (err, authResult) => {
      if (authResult?.accessToken) {
        const jwtPayload = JSON.parse(window.atob(authResult?.accessToken.split('.')[1]));

        setAuthenticationState({
          authenticated: true,
          loading: false,
          accessToken: authResult.accessToken,
          permissions: jwtPayload?.permissions ?? [],
        });
      } else {
        setAuthenticationState({
          authenticated: false,
          loading: false,
        });
      }
    });
  }, []);

  const graphClient = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );

          Sentry.captureMessage(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }

      if (networkError && networkError.statusCode === 401) {
        console.log('Detected a 401 error');
      }
    });

    const debugLogLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((result) => {
        console.info('⚡️Response', operation.operationName, {
          variables: operation.variables,
          data: result.data,
        });
        return result;
      });
    });

    const wsAuth = authenticationState.authenticated
      ? {
          type: 'OPENID_CONNECT',
          jwtToken: async () => {
            return `Bearer ${authenticationState.accessToken}`;
          },
        }
      : {
          type: 'API_KEY',
          apiKey: isDev() ? 'da2-2bmyax3itbegdmhmpvdpwxit4u' : 'da2-ik4v7w2nbrfufejcf6ny345wdu',
        };

    const includeCredentialsInRequest = isDoctorPortal() || isDev();

    const httpLink = new HttpLink({
      uri: apiURL,
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    const authLink = createAuthLink({
      url: apiURL,
      region: 'ap-southeast-2',
      auth: wsAuth,
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    const link = ApolloLink.from(
      isDev() ? [errorLink, debugLogLink, authLink, httpLink] : [errorLink, authLink, httpLink],
    );

    const client = new ApolloClient({
      cache: new InMemoryCache(),
      connectToDevTools: isDev(),
      link,
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    window.apolloClient = client;

    return client;
  }, [authenticationState.authenticated, authenticationState.accessToken]);

  if (authenticationState.loading)
    return (
      <NativeBaseProvider theme={theme}>
        <WebLoader />
      </NativeBaseProvider>
    );

  return (
    <Suspense fallback={<WebLoader />}>
      <BrowserRouter>
        <ApolloProvider client={graphClient}>
          <NativeBaseProvider theme={theme}>
            <WebApp authenticated={authenticationState?.authenticated} />

            {devStateIsLocalHost && <DevSwitcher />}
          </NativeBaseProvider>
        </ApolloProvider>
      </BrowserRouter>
    </Suspense>
  );
}

function AppWebNewAuth() {
  const { isAuthenticated, logout, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [authenticationState, setAuthenticationState] = useRecoilState(authenticationStateAtom);

  const [authExpired, setAuthExpired] = useState(false);

  useEffect(() => {
    setAuthenticationState({
      authenticated: isAuthenticated,
      loading: isLoading,
    });
  }, [isAuthenticated, isLoading]);

  const graphClient = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );

          Sentry.captureMessage(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }

      if (isAuthenticated && networkError && [401, 403].includes(networkError.statusCode)) {
        setAuthExpired(true);
      }
    });

    const debugLogLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((result) => {
        console.info('⚡️Response', operation.operationName, {
          variables: operation.variables,
          data: result.data,
        });
        return result;
      });
    });

    const wsAuth = isAuthenticated
      ? {
          type: 'OPENID_CONNECT',
          jwtToken: async () => {
            try {
              const token = await getAccessTokenSilently();

              return `Bearer ${token}`;
            } catch (e) {
              setAuthExpired(true);

              return '';
            }
          },
        }
      : {
          type: 'API_KEY',
          apiKey: isDev() ? 'da2-2bmyax3itbegdmhmpvdpwxit4u' : 'da2-ik4v7w2nbrfufejcf6ny345wdu',
        };

    const includeCredentialsInRequest = isDoctorPortal() || isDev();

    const httpLink = new HttpLink({
      uri: apiURL,
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    const authLink = createAuthLink({
      url: apiURL,
      region: 'ap-southeast-2',
      auth: wsAuth,
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    const link = ApolloLink.from(
      isDev() ? [errorLink, debugLogLink, authLink, httpLink] : [errorLink, authLink, httpLink],
    );

    const client = new ApolloClient({
      cache: new InMemoryCache(),
      connectToDevTools: isDev(),
      link,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    window.apolloClient = client;

    return client;
  }, [isAuthenticated, setAuthExpired]);

  if (authenticationState.loading)
    return (
      <NativeBaseProvider theme={theme}>
        <WebLoader />
      </NativeBaseProvider>
    );

  return (
    <Suspense fallback={<WebLoader />}>
      <BrowserRouter>
        <ApolloProvider client={graphClient}>
          <NativeBaseProvider theme={theme}>
            <WebApp authenticated={isAuthenticated} />

            {devStateIsLocalHost && <DevSwitcher />}

            {authExpired && (
              <Modal isOpen={true} size="xl">
                <Modal.Content maxWidth="350">
                  <Modal.Header>Session Expired</Modal.Header>
                  <Modal.Body>
                    <VStack space={2}>
                      <Text>Your session has expired and you will need to re-login again.</Text>

                      <Text>Also ensure you are connected via CloudFlare WARP</Text>
                    </VStack>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      flex="1"
                      onPress={async () => {
                        await logout({ logoutParams: { returnTo: window.location.origin } });

                        setAuthenticationState({ authenticated: false, accessToken: null });
                      }}
                    >
                      Login Again
                    </Button>
                  </Modal.Footer>
                </Modal.Content>
              </Modal>
            )}
          </NativeBaseProvider>
        </ApolloProvider>
      </BrowserRouter>
    </Suspense>
  );
}

function AuthCallbackPage() {
  useEffect(() => {
    webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        console.log(err);

        Sentry.captureMessage(err);

        window.location = '/';

        return;
      }

      if (authResult?.appState?.returnTo) {
        window.location = authResult.appState.returnTo;

        return;
      }

      window.location = '/';
    });
  }, []);
  return <View />;
}

const AppOuter = function () {
  const isAuth = window.document.location.pathname === '/auth-callback';

  if (useNewAuth) {
    const { domain, audience, clientID } = configForBrand();

    return (
      <RecoilRoot>
        <Auth0Provider
          domain={domain}
          clientId={clientID}
          authorizationParams={{
            audience,
            scope: 'openid profile email offline_access',
            redirect_uri: window.location.origin,
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <AppWebNewAuth />
        </Auth0Provider>
      </RecoilRoot>
    );
  }

  return <RecoilRoot>{isAuth ? <AuthCallbackPage /> : <AppWeb />}</RecoilRoot>;
};

export default Sentry.withErrorBoundary(AppOuter, {
  showDialog: false,
});
