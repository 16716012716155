import { atom } from 'recoil';

const localStorageEffect =
  (key: string) =>
  // @ts-ignore
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export type DevStateType = {
  brand: string;
  dev: boolean;
};

export const devStateIsLocalHost = window.location.hostname === 'localhost';
export const devStateLocalStorageKey = 'devEnvState';

export const devState = atom<DevStateType>({
  key: devStateLocalStorageKey,
  default: { brand: 'heyfella', dev: true },
  effects: [localStorageEffect(devStateLocalStorageKey)],
});
