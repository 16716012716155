import { useBrandName } from './useBrand';
import { useCustomer } from './useCustomer';
import { useIsDev } from './useIsDev';
import { Platform } from 'react-native';
import dayjs from 'dayjs';

export const useAnalytics = () => {
  const { brand } = useBrandName();
  const { customer } = useCustomer();

  const isDev = useIsDev();

  const identifyUserByEmail = (email, additionalProps) => {
    if (_cio && _cio?.identify) {
      _cio.identify({
        created_at: dayjs().unix(),
        email,
        id: email,
        ...additionalProps,
      });
    }
  };

  const trackEvent = (name, _variables) => {
    const variables = { ..._variables, platform: Platform.OS };

    if (isDev) {
      console.log('📊Tracking event', { name, variables });
    }

    try {
      _cio.track(name, variables ?? {});
    } catch (e) {}

    if (name === 'submitted_request' && brand === 'onlinedoctor') {
      try {
        __adroll.record_user({ adroll_segments: '05b927ba' });
      } catch (err) {}
      try {
        dataLayer.push({ event: 'purchase', conversionValue: 25 });
      } catch (err) {}
      try {
        fbq('track', 'Purchase', { currency: 'AUD', value: 25.0 });
      } catch (e) {}
    } else {
      try {
        dataLayer.push({ event: name, email: customer?.email, ...variables });
      } catch (err) {}
    }
  };

  return { trackEvent, identifyUserByEmail };
};
