import React, { useState } from 'react';
import { contentfulGQLClient } from '../utils/contentful';
import { Box, Text } from 'native-base';
import { useQuery } from '@apollo/client';
import { graphql } from '../../__generated__';
import { Banner } from '../../__generated__/graphql';
import { useBrand } from '../hooks/useBrand';

export function BannerBox() {
  const [bannerData, setBannerData] = useState<Banner>();

  const brand = useBrand();

  useQuery(
    graphql(`
      query bannerCollection($brand: String) {
        bannerCollection(where: { brand: $brand, show: true }) {
          __typename
          items {
            show
            message
            backgroundColor
            textColor
            brand
            __typename
          }
        }
      }
    `),
    {
      fetchPolicy: 'cache-first',
      variables: {
        brand,
      },
      client: contentfulGQLClient,
      onCompleted: (data) => {
        const banner = data?.bannerCollection?.items?.[0];

        if (banner && banner?.show) {
          setBannerData(banner as Banner);
        }
      },
    },
  );

  if (!bannerData) return null;

  return (
    <Box p={2} bg={bannerData?.backgroundColor}>
      <Text textAlign={'center'} color={bannerData?.textColor} fontSize={'md'} fontWeight={600}>
        {bannerData?.message}
      </Text>
    </Box>
  );
}
