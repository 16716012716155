import { devStateLocalStorageKey } from '../../DevSwitcher/state/devState';

export const brandForDomain = () => {
  if (!window.location.hostname) return '';

  if (window.location.hostname === 'localhost') {
    const localStorageData = localStorage.getItem(devStateLocalStorageKey);

    if (localStorageData) {
      const { brand } = JSON.parse(localStorageData);

      if (brand === 'portal') return 'onlinedoctor';

      return brand;
    }

    return 'heyfella';
  }

  // ------------------
  if (window.location.hostname.indexOf('leafdoctors.com.au') > -1) return 'leafdoctors';
  if (window.location.hostname.indexOf('heyfella.com.au') > -1) return 'heyfella';
  if (window.location.hostname.indexOf('truegreen.health') > -1) return 'truegreen';
  return 'onlinedoctor';
};
